body {
  margin: 0;
  font-family: "Arial";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  user-select: none;
  box-sizing: border-box;
  font-family: Microsoft Jhenghei, 微軟正黑體, sans-serif;
}
.fade-in-section {
  opacity: 0;

  transition: opacity 2s ease-out;
  will-change: opacity, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  display: flex;
}

#root {
  max-width: 100vw;
}
